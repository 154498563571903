import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import "./../App.css"


class Routes extends Component {
    render() {
        if (!this.props.data) return null;
    
       
        const trasa1 = this.props.data.trasa1;
        const trasa2 = this.props.data.trasa2;
        const trasa3 = this.props.data.trasa3;
        const trasa4 = this.props.data.trasa4;
         
       
        return (
    
            <section id="routes">
              <Fade duration={1000}>
                <h2>T R A S Y</h2>
                    <div className="row">
                        <div className="tweleve columns">

                                  
                                

                                    <h3>5 lipca - środa</h3>
                                    <h3>Trasa "Na dzień dobry" (30km)</h3>
                                    <p>{trasa1.opis}</p>
                                    <p>{trasa1.etapy}</p>

                                    <iframe className="routes-img"
                                        src="https://en.frame.mapy.cz/s/nevefunebu" width="500" height="380" frameborder="0">
                                    </iframe>
                                 
                                                          
                                    <h3>6 lipca</h3>
                                    <h3>Trasa "Wiślana" (58km)</h3>
                                    <p>{trasa2.opis2}</p>
                                    <p>{trasa2.etapy2}</p>

                                    <iframe className="routes-img"
                                       src="https://en.frame.mapy.cz/s/remesasove" width="500" height="380" frameborder="0">
                                    </iframe> 

                                    <h3>7 lipca</h3>
                                    <h3>Trasa  "Gościu, siądź pod mym liściem" (60km)</h3>
                                    <p>{trasa3.opis3}</p>
                                    <p>{trasa3.etapy3}</p>

                                    <iframe className="routes-img"
                                     src="https://en.frame.mapy.cz/s/cozabevajo" width="400" height="380" frameborder="0">
                                    </iframe>

                                    <h3>8 lipca</h3>                                 
                                    <h3>Trasa "Czarna płyta" (55km)</h3>
                                    <p>{trasa4.opis4}</p>
                                    <p>{trasa4.etapy4}</p>
                                    
                                    <p>UWAGA! Trasy różnią się krótkim odcinkiem, jeden prowadzi szutrową, ubitą drogą a drugi jest w pełni asflatowy</p><br/><br/>
                                    <p>trasa z odcinkiem szutrowym</p>    
                                    <iframe className="routes-img"
                                        src="https://en.frame.mapy.cz/s/cocafujeta" width="400" height="380" frameborder="0">
                                    </iframe>
                                    <p>trasa w pełni asfaltowa</p>    
                                    <iframe className="routes-img"
                                        src="https://en.frame.mapy.cz/s/rakuzepohu" width="400" height="380" frameborder="0">
                                    </iframe>



                </div>
               
          </div>

              </Fade>
    
    
            {/* <Fade left duration={1000} distance="40px">
            
              <div className="row">
              Poniżej znajdują się trasy dojazdowe na zlot z najbliższych stacji kolejowych. <br/> Kliknięcie na "Show on map" przenosi do mapy.cz, gdzie jest możliwość pobrania trasy w GPX
                <div className="twelve columns collapsed">
                <div id="portfolio-wrapper"
                    className="bgrid-thirds s-bgrid-thirds cf">
                 <b>Garbatka</b> <br/>
                  <iframe src="https://en.frame.mapy.cz/s/juzonapeho" width="400" height="280" frameborder="0"></iframe>
                  </div>
                  <div>
                  <b>Pionki</b> <br/>
                  <iframe src="https://en.frame.mapy.cz/s/jevasurota" width="400" height="280" frameborder="0"></iframe> 
                  </div>
                  <div>
                  <b>Dęblin</b> <br/>
                  <iframe src="https://en.frame.mapy.cz/s/nemogevure" width="400" height="280" frameborder="0"></iframe>
                  </div>
                  <div>
                  <b>Dobieszyn</b> <br/>
                  <iframe src="https://en.frame.mapy.cz/s/dezolamoca" width="400" height="280" frameborder="0"></iframe>
                  </div>
    
                </div>
              </div>
            </Fade> */}
          </section>
    
        );
      }
    }
    
  
  export default Routes;
  