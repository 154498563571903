import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";
import { GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";

let id = 0;
class Registration extends Component {
  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project; //C:\Users\karolinas\Documents\firmowe\zlot_kozienice\zlot\public\file\users_registration_list.xlsx
    const github = this.props.data.github;

    return (
      <section id="registration">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>REJESTRACJA</h1>

             
                <GoogleFormProvider  >

                <iframe  className="registration-form" src="https://docs.google.com/forms/d/e/1FAIpQLSefdeF7QT5dFsNHC8uAZeFz5jqYufNNP0SXC2bQu07W8KB2GQ/viewform"
              >
                  Ładuję…
                </iframe>
              </GoogleFormProvider>

              
              
              <ul className=" social registration-button">
              <h4>Jeśli formularz wyświetla się nieprawidłowo użyj linku <a href={"https://docs.google.com/forms/d/e/1FAIpQLSefdeF7QT5dFsNHC8uAZeFz5jqYufNNP0SXC2bQu07W8KB2GQ/viewform"}> <i style={{color: "blue"}}>Formularz rejestracyjny</i></a> </h4>
                <a href={"https://docs.google.com/spreadsheets/d/1IoVEa6LyLN4U6d9auv-pIsgrK2W6lv6rbPZDqNn_kqs/edit#gid=0"} className="button btn project-btn">
                  <i style={{color: "white"}}></i>Lista uczestników
                </a>
              </ul>        
              
            </div>
            
          </div>
          
        </Fade>

      </section>
    );
  }
}

export default Registration;
