import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

let id = 0;
class HowToGet extends Component {
  render() {
    if (!this.props.data) return null;

   
    const bio = this.props.data.bio;
     
   
    return (

        <section id="howtoget">
          <Fade duration={1000}>
         
          <div className="row">
            <div className="twelve columns ">
              
           
              <h2>Dojazd</h2>
              <img
                className="howtoget-img"
                src = "images\poziomka_dobieszyn.jpg"
                alt="Dobieszyn"
              />
             
              <p>{bio}<br /> do miejscowości:<br />
                <b>Garbatka-Letnisko</b> - ok 17km od Kozienic<br />
                <b>Pionki</b> - ok 18km od Kozienic <br />
                <b>Dobieszyn</b> - ok 30km od Kozienic<br />
                <b>Dęblin</b> - ok 28km od Kozienic<br /> <br />
              <p>Jeśli ktoś planuje dojazd samochodem to na terenie ośrodka jest bezpłatny parking</p></p>
              
            
            </div>
           
      </div>
        
             
          </Fade>


        <Fade left duration={1000} distance="40px">
        
          <div className="row">
          Poniżej znajdują się trasy dojazdowe na zlot z najbliższych stacji kolejowych. <br/> Kliknięcie na "Show on map" przenosi do mapy.cz, gdzie jest możliwość pobrania trasy w GPX
            <div className="twelve columns collapsed">
            <div id="portfolio-wrapper"
                className="bgrid-thirds s-bgrid-thirds cf">
             <b>Garbatka</b> <br/>
              <iframe src="https://en.frame.mapy.cz/s/juzonapeho" width="400" height="280" frameborder="0"></iframe>
              </div>
              <div>
              <b>Pionki</b> <br/>
              <iframe src="https://en.frame.mapy.cz/s/jevasurota" width="400" height="280" frameborder="0"></iframe> 
              </div>
              <div>
              <b>Dęblin</b> <br/>
              <iframe src="https://en.frame.mapy.cz/s/nemogevure" width="400" height="280" frameborder="0"></iframe>
              </div>
              <div>
              <b>Dobieszyn</b> <br/>
              <iframe src="https://en.frame.mapy.cz/s/dezolamoca" width="400" height="280" frameborder="0"></iframe>
              </div>

            </div>
          </div>
        </Fade>
      </section>

    );
  }
}

export default HowToGet
