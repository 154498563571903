import React, { Component } from "react";
import Fade from "react-reveal";
import Zmage from "react-zmage";



class Accomodation extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/" + this.props.data.image;
    const noclegiuwaga = this.props.data.noclegiuwaga; 
    const accomodation = this.props.data.accomodation;
    const plan = "images/" + this.props.data.plan;


    return (
      <section id="accomodation">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Zlot Miłośników Rowerów Poziomych"
              />

              <p className="address">
                <span>Ośrodek Rekreacji i Turystyki <br />
                  Kozienickiego Centrum Rekreacji i Sportu</span>
                <br />
                <span>
                  ul. Boh. Studzianek 30
                  <br />
                  26-900 Kozienice
                </span>

              </p>
            </div>
            <div className="nine columns main-col">
              <h2>Noclegi</h2>

              <p>{accomodation} <br />
              </p>

              <div className="attention-text">
                WAŻNE:<br/>REZERWACJA NOCLEGÓW DO 30 MAJA!!! <br/>Później będzie można dołączyć jeśli będą wolne miejsca w domkach, ewentualnie namiot (tu można w każdej chwili się decydować )
                <br /> {noclegiuwaga} <br/>
                <h3>Wyżywienie <br/>
                Na terenie ośrodka jest restauracja, w której można zamówić śniadania i kolacje. Cena to 25zł za posiłek, czyli 50zł za śniadanie i kolację. Chętnych proszę o informację w formularzu rejestracyjnym lub mailem/smsem do organizatorki</h3>
              </div>
              <p>
                <h3>CENNIK ze strony ośrodka:</h3>

                <h4>Pensjonat:</h4>
                <b>Pokój 1-osobowy z łazienką</b> - 110 zł<br />
                <b>Pokój 2-osobowy z łazienką</b> - 150 zł<br />
                <b>Apartament 2-osobowy</b> - 200 zł (wolne tylko od 05.07)<br />
                <b>Dostawka do apartamentu - maksymalnie 2 łóżka</b> - 35 zł/łóżko<br />


                <h4>Domki:</h4>
                <b>Domek dwupokojowy (5 osobowy) z łazienką  </b>- 320 zł<br />
                <b>Domek piętrowy (6 osobowy) z łazienką  </b>- 400 zł<br />
                <b>Dostawka w domku  </b>- 25 zł / łóżko<br />

                <h4>Pole namiotowe:</h4>
                <b>Pobyt 1 osoby dorosłej</b> - 25 zł <br />
                <b>Pobyt dzieci do lat 7</b> - bezpłatnie<br />
                <b>Pobyt dzieci powyżej 7 lat do 18 lat</b> - 12 zł<br />
                <b>Ustawienie namiotu</b> - bezpłatnie<br />
              </p>

            </div>

          </div>
          <img className="plan-img"
            src={plan}
            alt="Plan ośrodka"
          />
        </Fade>
      </section>
    );
  }
}

export default Accomodation;
