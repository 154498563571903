import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;


    const name = this.props.data.name;
    const description1 = this.props.data.description1;
    const description2 = this.props.data.description2;
    const description3 = this.props.data.description3;
    const description4 = this.props.data.description4;
    const burmistrz = this.props.data.burmistrz;

   // const herb_gminy = "images/" + this.props.data.herb_gminy;

    return (
      <header id="home">
     

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#accomodation">
              Noclegi
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#howtoget">
              Dojazd
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#registration">
                Rejestracja
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#schedule">
                Program
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#routes">
                Trasy
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#tshirts">
                Koszulki
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Kontakt
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description1}</h3>
              <h3>{description2}</h3>
              <h3>{description3}</h3>
            </Fade>
            
            <hr />
          </div>
          <div className="responsive-headline">
            <h2>{description4}</h2>
              <h2>{burmistrz}</h2>
              
          </div>
              {/* <img className="herb-img"
            src={herb_gminy}
            alt="Herb Gminy Kozienice"
          /> */}
        </div>

    

        <p className="scrolldown">
          <a className="smoothscroll" href="#accomodation">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
