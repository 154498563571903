import React, { Component } from "react";
import Fade from "react-reveal";
import Zmage from "react-zmage";



class Schedule extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/" + this.props.data.image;
    const noclegiuwaga = this.props.data.noclegiuwaga; 
    const accomodation = this.props.data.accomodation;
    const plan = "images/" + this.props.data.plan;


    return (
      <section id="schedule">
        <Fade duration={1000}>
        <h2>PROGRAM ZLOTU</h2>
          <div className="row">
            <div className="three columns">
      
              <img
                className="schedule-img"
                src = "images\kochanowski.jpg"
                alt="Jan Kochanowski"
              />
              <img
                className="schedule-img"
                src = "images\bar_zrodla.jpg"
                alt="Królewskie Źródła - Bar"
              />
              <img
                className="schedule-img"
                src = "images\kozienice_palac.jpg"
                alt="Pałac w Kozienicach"
              />
              <img
                className="schedule-img"
                src = "images\koziolek.jpg"
                alt="Koziołek"
              />
              <img
                className="schedule-img"
                src = "images\elektrownia.jpg"
                alt="Elektrownia Kozienice"
              />
              <img
                className="schedule-img"
                src = "images\rezerwat_zrodla.png"
                alt="Królewskie Źródła"
              />
                <img
                className="schedule-img"
                src = "images\staw.jpg"
                alt="Staw Górny"
              />
                <img
                className="schedule-img"
                src = "images\plyta.jpg"
                alt="Izba Czarnej Płyty"
              />
                <img
                className="schedule-img"
                src = "images\kozienice_napis.jpg"
                alt="#Kozienice"
              />
                <img
                className="schedule-img"
                src = "images\jezioro.jpg"
                alt="Jezioro Kozienickie"
              />
            </div>
            <div className="nine columns main-col">
            
              
              <div className="attention-text">
              </div>
              <p>
                <h3>04.07 WTOREK</h3><hr/>
                <h4>od godz 14:00 - Przywitanie pierwszych gości, zakwaterowanie</h4> 
                <h4>19:00 - Spotkanie przy grillu</h4>
             
                <h3>05.07 ŚRODA</h3><hr/>
                <h4>10:30 - Wyjazd na trasę "Na dzień dobry" (ok. 30km)</h4>
                <h4>11:00 - Zwiedzanie Muzeum Regionalnego w Kozienicach (wstęp wolny)</h4>
                <h4>po godz. 12:00 - wyjazd na dalszą część trasy do punktu widokowego "Kępeczki"</h4>
                <h4>krótki piknik i powrót do bazy noclegowej</h4>
                <h4>od godz 19:00 - Spotkanie przy grillu</h4>
              
                <h3>06.07 CZWARTEK</h3><hr/>
                <h4>09:00 - Wyjazd na trasę "Wiślana" (ok. 58km)</h4>
                <h4>ok. godz 10:00 - przeprawa promem na drugą stronę Wisły</h4>
                <h4>zwiedzanie muzeum w Maciejowicach</h4>
                <h4>obiad w Maciejowicach</h4>
                <h4>powrót na prom i do bazy noclegowej</h4>
                <h4>19:00 - Spotkanie przy grillu</h4>
               
                <h3>07.07 PIĄTEK</h3><hr/>
                <h4>09:00 - Wyjazd na trasę "Gościu, siądź pod mym liściem" (ok. 60km)</h4>
                <h4>ok. godz. 10:30 - krótki postój nad wodą w Garbatce-Letnisko</h4>
                <h4>11:00 - wyjazd na dalszą część trasy do Czarnolasu</h4>
                <h4>12:00 - zwiedzanie Muzeum Jana Kochanowskiego (25zł)</h4>
                <h4>ok. godz. 15:00 - zamówiony obiad w Garbatce-Zbyczyn</h4>
                <h4>po obiedzie powrót do bazy noclegowej</h4>
                <h4>19:00 - Spotkanie przy ognisku</h4>
             
                <h3>08.07 SOBOTA</h3><hr/>
                <h4>09:00 - Wyjazd na trasę "Czarna płyta" (ok. 60km)</h4>
                <h4>10:00 - Dojazd do Gościńca Królewskiego - wybór trasy asfaltowej albo ubitego szutru</h4>
                <h4>12:00 - Zwiedzanie "Izby Czarnej Płyty"</h4>
                <h4>po zwiedzaniu udamy się nad wodę "Górny Staw" na obiad we własnym zakresie</h4>
                <h4>ok. godz. 15:00 - wyjazd na dalszą część trasy</h4>
                <h4>zwiedzanie "Źródeł Królewskich"</h4>
                <h4>po zwiedzaniu powrót do bazy noclegowej</h4>
                <h4>19:00 - Spotkanie przy ognisku</h4>
                
                <h3>09.07 NIEDZIELA</h3><hr/>
                <h4>do godz. 12:00 - Pożegnanie </h4>
           
           
                

                
              </p>

            </div>

          </div>
          
        </Fade>
      </section>
    );
  }
}

export default Schedule;
