import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import "./../App.css"

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1></h1>
            </div>

            <div className="eight columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form >
                <fieldset>
                  <label >
                    Karolina Śmietanka - karola
                  </label>
      
                  <label >
                    email:    karolina.smietanka[małpa]gmail.com
                  </label>
                  <label >telefon:    505-385-392</label>



                </fieldset>
              </form>

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>


        </div>
      </section>
    );
  }
}

export default Contact;
