import React, { Component } from "react";
import Fade from "react-reveal";

class TShirts extends Component {
  render() {
    if (!this.props.data) return null;

    const tshirts = this.props.data.tshirts;
    const koszulka = "images/" + this.props.data.koszulka;


    return (


      <section id="tshirts">
        <Fade duration={1000}>

          <div className="row">
            
            <div className="six columns main-col">
              
              <h2>{tshirts}</h2>
              <h3>Koszulki zamówione według wyborów w formularzu rejestracyjnym</h3>
              <h3>Cena koszulki to 65zł </h3>
              <h3>Wpłaty na konto</h3>
              <h3> 05 1140 2004 0000 3002 8373 8853 </h3>
              <h3> lub można blikać :)</h3>
            
            </div>
            <img className="tshirts-img"
            src={koszulka}
            alt="koszulka"
          />
            <img 
            src="favicon.ico"
            alt="choinka"
          />
          </div>
         
        </Fade>
        
      </section>

    );
  }
}

export default TShirts;
